import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
      <path d="M9730 10477 c-1 -7 -4 -1893 -6 -4192 -2 -2299 -6 -4195 -9 -4213
l-6 -32 -3149 0 c-1733 0 -3150 -3 -3150 -6 0 -18 23 -43 50 -54 32 -13 38
-25 18 -34 -7 -3 -1 -3 15 0 20 5 27 2 27 -9 0 -10 13 -18 35 -23 19 -3 35
-10 35 -15 0 -4 10 -6 22 -4 12 3 40 0 63 -6 22 -6 60 -13 85 -15 140 -12 199
-16 230 -16 289 3 359 2 823 -11 70 -2 130 0 133 4 7 12 101 11 109 -1 4 -7
13 -7 24 0 12 6 22 5 29 -2 8 -8 12 -8 12 -1 0 6 17 8 48 4 26 -4 111 -7 189
-6 86 0 145 -4 147 -10 3 -7 7 -7 13 0 10 13 653 14 645 1 -3 -5 0 -6 6 -2 19
12 152 16 152 5 0 -6 9 -7 22 -3 11 3 47 6 80 5 32 -1 66 -1 76 -1 97 1 116 0
124 -8 5 -5 28 -6 56 0 60 11 207 13 250 4 17 -4 32 -4 32 1 0 5 28 6 63 3 34
-4 106 -5 160 -3 63 2 97 -1 97 -8 0 -6 6 -7 12 -2 17 10 548 10 548 0 0 -5 5
-5 10 -2 16 10 449 15 454 6 3 -5 26 -5 50 0 51 9 356 10 356 0 0 -6 385 -7
617 -2 67 2 126 0 132 -3 19 -12 272 8 318 25 68 26 128 132 102 180 -6 12 -8
31 -5 43 3 13 2 26 -2 30 -4 4 -7 50 -8 103 -1 70 -4 97 -15 104 -8 5 -9 9 -2
9 13 0 17 204 4 262 -5 20 -6 39 -3 42 3 3 7 59 9 123 2 65 5 154 7 198 2 44
-2 117 -8 163 -7 45 -9 82 -5 82 3 0 4 11 1 25 -2 14 1 31 7 39 7 9 8 17 2 20
-5 3 -7 23 -4 43 2 21 4 65 3 98 -1 58 -1 102 1 128 0 6 -2 64 -6 127 -9 161
-10 279 -3 317 3 17 3 34 0 38 -11 10 -11 349 0 363 7 8 7 12 -1 12 -13 0 -14
65 -2 127 4 23 4 46 0 50 -4 4 -3 27 3 51 8 31 7 46 -1 56 -14 17 -12 134 2
154 6 8 5 17 -3 27 -7 8 -10 21 -7 29 3 7 0 19 -6 25 -7 7 -7 11 -1 11 6 0 9
24 7 57 -1 36 2 60 9 64 9 6 7 14 -7 30 -11 12 -14 19 -6 14 10 -6 12 -1 6 26
-5 19 -8 66 -7 104 0 39 0 162 0 275 0 113 1 239 1 280 0 41 0 101 0 133 0 40
4 57 13 57 10 0 9 3 -1 9 -11 7 -15 37 -15 133 -1 68 3 134 8 147 6 17 5 22
-4 16 -9 -6 -10 -1 -4 16 4 13 6 51 4 84 -5 70 -4 383 2 537 1 58 0 101 -4 95
-4 -6 -6 46 -5 116 1 70 4 127 7 127 3 0 5 61 5 135 0 74 -4 135 -8 135 -5 0
-5 10 -1 22 4 12 7 100 7 196 0 130 -3 176 -12 182 -7 4 -9 10 -4 13 11 7 16
103 14 262 -1 66 -1 129 -1 140 -1 11 -1 67 -2 125 -2 115 -2 373 -1 460 1 30
0 144 -1 252 -1 108 1 222 4 252 3 32 2 56 -3 56 -5 0 -6 15 -3 34 4 20 2 37
-4 41 -7 4 -7 12 0 25 7 13 7 21 0 25 -6 4 -7 11 -4 17 4 6 6 31 5 57 -1 25
-3 86 -4 134 -2 48 0 90 5 93 4 3 6 15 3 27 -6 24 -12 111 -19 252 -2 50 -9
102 -14 117 -6 14 -13 47 -16 73 -3 25 -13 53 -21 61 -8 9 -15 22 -15 29 0 18
-16 28 -20 12z"/>
<path d="M3700 10181 c0 -17 5 -33 10 -36 6 -4 10 5 10 19 0 14 1 27 3 28 1 1
214 5 472 8 l470 5 -482 3 -483 2 0 -29z"/>
<path d="M4703 10203 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M4993 10203 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M5083 10203 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M5483 10203 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M5588 10203 c29 -2 74 -2 100 0 26 2 2 3 -53 3 -55 0 -76 -1 -47 -3z"/>
<path d="M5768 10203 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5818 10203 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5983 10203 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M6498 10203 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M6588 10203 c18 -2 45 -2 60 0 15 2 0 4 -33 4 -33 0 -45 -2 -27 -4z"/>
<path d="M6891 10206 c9 -8 2339 -13 2339 -5 0 5 -524 9 -1172 9 -644 0 -1169
-2 -1167 -4z"/>
<path d="M6417 9259 c-308 -18 -579 -82 -847 -201 -301 -133 -482 -243 -705
-433 -563 -477 -875 -1107 -875 -1765 0 -85 5 -198 10 -250 10 -95 50 -299 70
-355 l10 -30 -5 30 c-3 17 -16 95 -29 175 -32 190 -40 341 -27 522 25 340 125
674 274 918 22 36 45 74 52 85 24 44 111 173 148 220 118 151 284 328 300 318
5 -3 7 -1 3 5 -3 5 10 20 29 32 19 12 35 25 35 29 0 4 29 29 65 55 36 26 71
53 78 60 23 22 86 67 94 67 4 0 20 11 35 25 15 13 32 24 37 24 5 0 11 3 13 8
4 10 85 62 96 62 5 0 22 10 38 23 16 12 31 21 34 20 12 -6 40 9 34 19 -4 6 -1
9 7 6 7 -2 86 30 174 73 88 42 180 82 205 88 45 12 110 35 142 52 10 5 26 9
36 9 10 0 54 9 98 20 151 39 330 54 625 55 l275 0 40 -31 c21 -17 75 -55 119
-84 146 -97 265 -202 435 -385 53 -57 168 -210 255 -338 22 -32 45 -62 53 -66
11 -6 11 -11 -3 -25 -9 -9 -30 -23 -46 -32 -33 -17 -33 -8 1 -179 24 -118 56
-202 135 -351 36 -67 65 -129 65 -138 0 -13 12 -16 58 -17 l57 -1 -68 -13
c-125 -25 -258 -89 -241 -116 14 -23 50 -179 41 -176 -6 2 -15 16 -19 33 -21
74 -42 134 -46 134 -11 0 -327 -215 -386 -264 -75 -60 -334 -230 -462 -302
-57 -32 -174 -78 -181 -71 -3 4 5 11 18 16 13 5 44 26 69 48 25 21 59 49 75
62 17 12 57 48 90 80 33 31 96 88 140 126 106 91 234 230 256 280 24 53 24 96
-2 139 l-21 36 -103 0 -102 0 -44 -48 c-24 -26 -89 -110 -144 -186 -55 -76
-134 -182 -176 -236 -43 -55 -79 -105 -82 -112 -3 -10 -8 -9 -23 3 -10 9 -44
31 -74 48 -45 25 -49 30 -24 25 28 -5 32 -3 49 33 26 54 31 123 11 161 -17 34
-65 72 -90 72 -17 0 -22 -15 -7 -24 15 -10 21 -64 9 -97 l-11 -31 -61 7 c-33
4 -88 8 -121 9 -53 1 -61 4 -74 28 -14 24 -14 30 2 64 22 44 17 51 -23 34 -86
-35 -114 -176 -50 -240 20 -20 19 -20 -21 -40 -22 -12 -49 -30 -60 -41 -17
-17 -23 -18 -36 -7 -14 11 -105 124 -343 425 -93 118 -159 161 -257 170 -80 7
-129 -7 -126 -36 8 -67 314 -398 622 -672 l95 -84 -33 -3 c-79 -8 -300 95
-681 318 -283 165 -447 259 -462 265 -13 5 -21 -10 -42 -72 -25 -74 -46 -119
-46 -99 0 4 12 55 26 113 14 58 24 106 22 107 -2 1 -52 19 -113 39 -162 53
-252 68 -284 48 -12 -8 -12 -14 -1 -43 24 -60 132 -239 164 -271 17 -18 37
-44 45 -58 15 -31 61 -47 158 -56 61 -5 159 -41 238 -86 11 -6 35 -19 53 -28
18 -10 38 -22 45 -27 7 -6 29 -13 49 -17 21 -4 35 -11 32 -16 -3 -5 4 -9 15
-9 12 0 21 -4 21 -8 0 -7 39 -27 215 -107 75 -35 133 -57 170 -65 22 -5 65
-16 95 -26 30 -9 67 -18 83 -21 15 -3 27 -9 27 -13 0 -4 19 -13 43 -19 154
-40 -25 -37 -264 4 -129 23 -299 58 -489 101 -36 8 -87 15 -115 17 l-49 2 -7
-45 c-7 -48 -56 -177 -69 -185 -5 -3 2 22 15 56 26 65 46 155 37 164 -8 8 -89
-42 -197 -124 -100 -74 -265 -230 -265 -248 0 -10 96 -57 135 -66 14 -3 11 -5
-10 -6 -17 0 -51 10 -77 24 -28 15 -49 21 -53 15 -13 -20 -25 -249 -20 -350 9
-139 40 -315 75 -422 61 -183 249 -517 354 -627 71 -75 44 -56 -128 89 -82 69
-160 133 -175 143 -57 40 219 -217 294 -274 44 -33 87 -66 95 -72 8 -7 57 -38
107 -70 302 -190 528 -281 873 -348 103 -21 145 -23 395 -23 276 0 282 1 430
32 125 26 341 87 419 118 12 5 17 4 13 -3 -4 -5 -32 -18 -63 -28 -41 -13 -52
-20 -43 -27 10 -6 7 -7 -9 -3 -13 4 -48 -2 -83 -15 -33 -11 -84 -26 -112 -32
-29 -6 -46 -13 -39 -16 31 -10 272 50 272 68 0 4 12 11 28 14 34 8 154 57 166
68 6 4 20 8 33 8 13 0 27 9 34 23 11 21 11 21 -7 6 -10 -9 -30 -19 -44 -22
-14 -3 -36 -8 -50 -12 -14 -3 17 15 68 40 51 25 100 45 108 45 8 0 14 4 14 9
0 5 7 12 16 15 8 3 13 2 10 -3 -3 -5 1 -12 10 -15 8 -3 12 -2 9 4 -3 6 4 10
15 10 16 0 20 4 16 16 -5 12 -2 15 10 10 15 -6 101 27 114 44 3 3 28 19 55 34
47 27 127 83 185 131 40 33 120 116 163 170 21 28 46 54 53 58 21 12 188 268
222 340 75 158 96 292 89 549 -7 206 -9 227 -27 283 -10 31 -16 36 -34 31
-132 -38 -208 -47 -221 -25 -5 7 4 8 27 4 41 -8 177 20 197 40 14 14 13 19 -4
51 -28 53 -186 192 -315 278 -63 42 -120 76 -127 76 -18 0 -8 -65 22 -145 36
-98 37 -102 14 -60 -12 22 -35 75 -50 118 -15 43 -29 81 -32 83 -4 4 -231 -30
-477 -72 -302 -52 -388 -65 -504 -78 -65 -7 -130 -11 -144 -8 -21 4 -17 8 29
22 347 114 592 221 814 356 181 110 207 124 248 124 43 0 92 19 123 46 28 25
143 190 156 223 13 34 1 147 -18 165 -8 8 -14 28 -14 44 0 55 -40 266 -69 362
-33 110 -98 274 -142 362 l-31 61 -30 -37 c-39 -48 -49 -38 -13 14 33 47 36
39 -80 185 -169 213 -410 422 -691 599 -56 35 -52 43 10 16 27 -11 53 -17 58
-13 7 3 8 1 4 -5 -5 -9 -1 -9 17 -3 14 5 28 6 33 2 5 -5 42 -16 82 -26 41 -10
90 -26 110 -35 20 -10 40 -16 43 -14 12 7 242 -90 374 -158 69 -35 294 -188
363 -247 36 -31 62 -49 57 -42 -19 31 -141 128 -275 218 -99 66 -279 161 -411
217 -124 51 -388 130 -508 151 -46 8 -89 20 -96 25 -10 9 -362 32 -420 28 -11
0 -77 -4 -148 -9z m1498 -2037 c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10 -3 15
-9 12 -12z m-1472 -59 c3 -5 1 -424 -4 -932 -6 -739 -10 -926 -21 -932 -7 -5
-17 -4 -21 0 -14 14 -2 1659 13 1774 12 88 20 111 33 90z m1645 -658 c-3 -3
-9 2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z m-2708 -76 c318 -16 450 -16
488 0 18 7 35 11 39 7 3 -3 -55 -35 -131 -71 -160 -77 -237 -122 -364 -217
-109 -80 -252 -215 -284 -266 l-22 -35 30 -24 c43 -35 65 -37 145 -14 40 11
74 19 76 17 2 -2 -20 -13 -48 -26 -63 -26 -128 -29 -163 -7 -34 23 -36 22 -36
-18 0 -109 72 -342 161 -518 70 -139 72 -141 192 -296 49 -63 111 -148 139
-190 75 -112 148 -201 164 -201 21 0 17 17 -20 79 -71 117 -233 460 -223 470
7 7 15 7 24 0 10 -9 13 -7 13 9 0 11 -5 22 -10 24 -6 2 -15 30 -20 62 -5 32
-39 153 -75 269 -44 141 -64 217 -58 228 13 29 152 168 228 228 163 130 224
186 286 261 33 40 35 38 13 -21 -28 -80 -33 -139 -18 -226 35 -205 155 -434
303 -580 25 -25 45 -52 43 -59 -2 -8 1 -14 6 -14 4 0 17 -8 28 -18 57 -53 96
-67 184 -67 73 1 92 5 135 28 74 40 152 112 217 202 70 95 101 156 128 250 34
117 43 191 27 223 -8 15 -17 50 -21 78 -7 58 -43 155 -82 221 -27 46 -25 74 2
35 8 -12 20 -20 25 -16 5 3 8 2 7 -3 -3 -8 37 -53 47 -53 11 -1 129 -81 152
-104 13 -14 63 -53 111 -87 48 -35 89 -67 90 -71 2 -4 8 -8 14 -8 6 0 21 -11
34 -25 13 -14 21 -25 18 -25 -11 0 52 -47 81 -61 25 -12 29 -19 27 -44 -5 -49
-27 -115 -82 -251 -58 -141 -110 -304 -110 -341 0 -14 6 -23 15 -23 19 0 19
-18 1 -39 -7 -9 -55 -90 -106 -181 -94 -168 -144 -243 -237 -361 -51 -66 -53
-67 -117 -78 -142 -25 -219 -30 -421 -31 -228 0 -406 20 -540 60 -38 12 -118
34 -176 50 -198 54 -519 210 -615 300 -35 32 -118 149 -185 260 -31 51 -122
257 -135 303 -10 40 -10 55 1 87 8 22 14 47 13 57 0 10 5 68 11 128 7 67 8
175 5 275 -10 238 -8 246 73 312 47 38 75 70 97 112 l30 59 86 -7 c47 -4 177
-11 290 -17z m2746 -36 c67 -82 97 -113 111 -113 7 0 13 5 13 12 0 6 2 9 5 6
3 -3 -1 -63 -9 -134 -8 -71 -18 -183 -21 -249 -8 -139 9 -321 31 -346 12 -13
12 -18 2 -21 -7 -3 -28 -41 -45 -84 -93 -227 -297 -488 -479 -614 -120 -83
-366 -204 -524 -258 -80 -28 -167 -60 -193 -72 -27 -12 -51 -20 -53 -17 -3 2
39 49 92 103 53 55 89 86 79 71 -38 -65 -2 -44 101 59 234 233 315 342 429
576 62 128 83 201 100 355 6 57 -2 106 -16 97 -5 -3 -9 9 -9 27 0 35 -6 36
-92 3 l-47 -18 -83 41 c-46 23 -92 49 -103 58 -11 9 2 5 30 -8 28 -14 77 -34
110 -46 60 -22 62 -22 109 -4 97 36 88 65 -64 200 -184 164 -334 259 -525 334
-55 22 -118 49 -140 60 l-40 19 40 -7 c44 -9 484 -5 770 7 99 4 226 8 281 8
l102 2 38 -47z m-1366 -226 c37 -60 44 -83 48 -135 5 -69 -14 -154 -54 -252
-28 -67 -31 -54 -5 23 11 30 27 92 36 137 23 110 8 166 -71 275 -12 17 -27 41
-33 55 -11 24 -11 24 12 -3 13 -16 43 -61 67 -100z m-654 56 c-36 -72 -46
-136 -36 -227 10 -89 6 -115 -6 -39 -18 113 -5 213 36 281 30 48 34 39 6 -15z
m-19 -345 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m2064 -705
c-39 -53 -48 -63 -55 -63 -4 0 9 20 30 45 40 49 58 62 25 18z m-77 -95 c-47
-53 -82 -88 -124 -124 -25 -21 -5 4 45 54 49 51 91 92 94 92 2 0 -4 -10 -15
-22z m-244 -222 c0 -2 -8 -10 -17 -17 -16 -13 -17 -12 -4 4 13 16 21 21 21 13z
m-75 -52 c-26 -20 -55 -36 -55 -30 0 5 63 46 70 46 3 -1 -4 -8 -15 -16z m-90
-54 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z"/>
<path d="M5780 9073 c-157 -57 -351 -169 -555 -322 -114 -84 -355 -322 -355
-348 0 -35 43 -74 86 -80 l41 -5 94 113 c141 171 257 284 426 416 15 13 79 53
141 89 61 37 112 68 112 70 0 6 -73 -30 -160 -79 -114 -65 -131 -73 -98 -45
37 31 211 129 262 148 23 8 43 18 44 23 8 26 -4 32 -38 20z m-300 -222 c0 -5
-7 -12 -16 -15 -14 -5 -15 -4 -4 9 14 17 20 19 20 6z"/>
<path d="M8350 8557 c35 -37 68 -67 73 -67 9 0 -46 56 -100 102 -21 17 -9 2
27 -35z"/>
<path d="M8440 8474 c0 -5 20 -30 45 -54 25 -24 45 -42 45 -39 0 2 -20 26 -45
53 -25 27 -45 45 -45 40z"/>
<path d="M4792 8332 c-106 -165 -237 -526 -258 -709 -8 -72 -16 -65 104 -83
75 -11 84 -11 102 5 32 29 172 273 208 362 52 130 79 286 50 295 -6 2 -11 21
-10 41 1 33 -2 38 -32 51 -19 8 -52 30 -74 50 -22 20 -44 36 -50 36 -5 0 -24
-21 -40 -48z"/>
<path d="M8540 8366 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
<path d="M6170 8221 c-131 -43 -269 -103 -385 -169 -121 -69 -136 -85 -90
-102 14 -5 25 -11 25 -13 0 -8 -50 5 -56 14 -16 27 -160 -108 -218 -205 -20
-33 -36 -72 -36 -88 0 -27 2 -28 49 -28 95 0 142 -12 200 -51 30 -20 59 -35
64 -32 12 7 73 132 113 233 53 135 88 180 214 275 36 27 67 52 70 55 3 3 22
16 43 29 21 13 36 25 33 28 -5 5 -222 -101 -245 -120 -11 -9 -13 -9 -7 0 9 17
117 76 251 140 99 47 123 64 98 69 -4 1 -60 -15 -123 -35z m-376 -286 c-4 -8
-14 -15 -23 -15 -14 1 -13 3 3 15 25 19 27 19 20 0z"/>
<path d="M6522 8249 c2 -5 47 -30 101 -54 111 -51 172 -88 290 -178 46 -36 93
-69 103 -75 15 -9 17 -13 7 -23 -10 -10 -15 -10 -25 2 -7 8 -22 22 -33 29 -18
14 -19 13 -8 -1 7 -9 9 -20 5 -24 -4 -5 -1 -5 6 -1 8 5 12 -2 12 -23 0 -61 72
-201 137 -265 29 -29 32 -29 134 -29 l104 1 3 37 c6 72 -84 212 -189 296 -30
24 -43 29 -52 20 -6 -6 -18 -11 -26 -11 -11 0 -10 4 5 21 11 12 14 23 8 27 -5
4 -31 22 -57 40 -85 59 -233 130 -369 176 -140 48 -162 53 -156 35z"/>
<path d="M4090 6176 c0 -8 4 -17 9 -20 5 -4 7 3 4 14 -6 23 -13 26 -13 6z"/>
<path d="M3703 5520 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M3704 5030 c0 -63 1 -89 3 -57 2 31 2 83 0 115 -2 31 -3 5 -3 -58z"/>
<path d="M7565 4670 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M7528 4663 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3704 4510 c0 -52 1 -74 3 -47 2 26 2 68 0 95 -2 26 -3 4 -3 -48z"/>
<path d="M3700 3770 c0 -271 2 -491 5 -488 7 7 7 970 0 976 -3 3 -5 -217 -5
-488z"/>
<path d="M5750 3925 c0 -74 -2 -135 -4 -135 -2 0 -23 9 -47 20 -88 40 -212 19
-282 -48 -98 -93 -107 -272 -19 -365 52 -56 111 -82 177 -81 63 2 119 20 148
48 22 22 36 13 29 -19 -4 -26 -3 -26 44 -23 l49 3 3 345 c1 190 0 355 -3 368
-5 19 -12 22 -50 22 l-45 0 0 -135z m-63 -211 c41 -26 58 -64 58 -129 0 -132
-108 -212 -214 -157 -95 48 -114 173 -40 257 17 19 45 39 63 44 38 12 103 4
133 -15z"/>
<path d="M4886 4029 c-48 -38 -24 -109 37 -109 47 0 65 72 27 110 -25 25 -31
25 -64 -1z"/>
<path d="M8286 3688 l4 -363 48 0 c38 0 47 3 46 18 0 9 -1 172 -2 362 l-2 345
-49 0 -49 0 4 -362z"/>
<path d="M4552 4017 c-30 -32 -28 -62 7 -86 36 -26 53 -26 74 -3 21 23 22 61
1 90 -20 28 -55 28 -82 -1z"/>
<path d="M6087 4022 c-36 -40 -10 -112 41 -112 30 0 63 46 56 79 -12 50 -66
68 -97 33z"/>
<path d="M7056 4019 c-31 -25 -34 -61 -6 -89 23 -23 39 -25 75 -8 32 14 35 68
5 98 -25 25 -41 25 -74 -1z"/>
<path d="M7337 3943 c-4 -3 -7 -35 -7 -69 0 -57 -2 -63 -22 -66 -19 -2 -23 -9
-23 -38 0 -31 3 -35 28 -38 l27 -3 0 -155 c0 -233 16 -257 166 -252 l89 3 3
43 3 42 -65 0 c-95 0 -96 2 -96 176 l0 144 66 0 65 0 -3 43 c-3 37 -6 42 -28
43 -14 0 -42 1 -62 2 l-38 2 0 65 0 65 -48 0 c-27 0 -52 -3 -55 -7z"/>
<path d="M8678 3827 c-63 -18 -114 -59 -128 -103 -10 -30 -9 -43 5 -80 21 -57
52 -77 160 -109 49 -14 97 -33 107 -42 25 -22 19 -51 -14 -76 -26 -19 -32 -20
-80 -8 -29 8 -70 23 -90 35 l-37 21 -27 -21 c-38 -28 -40 -39 -12 -67 68 -68
236 -87 312 -37 92 61 101 189 18 248 -15 11 -69 30 -120 42 -102 24 -128 40
-112 70 24 45 104 54 171 20 44 -23 73 -19 89 11 35 64 -126 128 -242 96z"/>
<path d="M4870 3821 c0 -3 -2 -97 -5 -209 -3 -112 -3 -223 1 -248 l6 -45 42 3
41 3 0 250 0 250 -42 1 c-24 0 -43 -2 -43 -5z"/>
<path d="M6486 3815 c-99 -35 -166 -133 -166 -241 0 -79 15 -117 68 -171 60
-61 95 -77 177 -77 58 0 78 4 119 27 27 15 51 25 54 23 2 -3 1 -25 -2 -49 -8
-49 -52 -103 -93 -113 -48 -12 -152 3 -208 31 l-55 26 -30 -29 c-17 -17 -30
-34 -30 -39 0 -5 36 -27 81 -48 77 -38 86 -40 178 -40 112 0 156 18 214 86 46
53 49 79 45 379 l-3 235 -42 3 c-40 3 -43 1 -43 -22 0 -14 -1 -26 -2 -26 -2 0
-30 14 -62 30 -66 34 -134 39 -200 15z m162 -99 c95 -50 119 -166 49 -240 -98
-104 -267 -37 -267 106 0 109 122 184 218 134z"/>
<path d="M7826 3816 c-58 -22 -90 -49 -97 -83 -3 -17 -20 -48 -37 -69 -83
-104 -59 -265 47 -321 74 -39 209 -28 250 20 17 21 31 13 31 -19 0 -22 4 -24
45 -24 l45 0 0 208 c0 222 -4 239 -52 275 -36 27 -174 34 -232 13z m131 -166
c42 -25 56 -54 48 -98 -10 -54 -32 -91 -68 -112 -81 -50 -187 2 -187 90 0 103
121 173 207 120z"/>
<path d="M4542 3573 l3 -248 43 -3 42 -3 0 250 0 251 -45 0 -45 0 2 -247z"/>
<path d="M6070 3570 l0 -250 45 0 45 0 0 250 0 250 -45 0 -45 0 0 -250z"/>
<path d="M7040 3570 l0 -251 43 3 42 3 0 245 0 245 -42 3 -43 3 0 -251z"/>
<path d="M3700 2905 c0 -164 3 -295 7 -292 7 7 4 579 -3 586 -2 2 -4 -130 -4
-294z"/>
<path d="M6170 3018 l0 -53 -71 0 c-65 0 -73 -2 -95 -28 -36 -43 -32 -88 11
-126 20 -17 41 -31 48 -32 44 -4 94 12 109 35 4 6 10 -3 14 -22 5 -24 10 -30
18 -22 7 7 11 61 11 146 0 109 -3 137 -15 144 -26 17 -30 11 -30 -42z m-30
-102 c18 -8 26 -20 28 -42 3 -27 -1 -33 -26 -42 -63 -22 -134 14 -124 63 7 41
59 50 122 21z"/>
<path d="M7501 2938 c-6 -73 -9 -142 -7 -153 2 -11 5 -22 6 -25 2 -13 30 36
30 54 0 12 5 28 10 36 8 12 17 6 53 -32 40 -44 67 -60 67 -40 0 4 -14 27 -30
50 l-31 41 31 27 c35 32 41 74 10 74 -12 0 -24 -9 -29 -23 -9 -24 -60 -64 -62
-50 -1 5 -1 45 1 91 2 75 0 82 -17 82 -18 0 -21 -12 -32 -132z"/>
<path d="M5420 3045 c-1 -5 -1 -22 0 -38 1 -17 -4 -29 -15 -33 -12 -5 -13 -9
-5 -14 8 -5 13 -36 14 -80 1 -63 4 -75 25 -91 24 -19 71 -26 71 -10 0 11 -29
23 -40 16 -12 -7 -19 47 -9 67 5 9 9 30 9 47 0 23 7 34 28 45 l27 14 -25 6
c-14 3 -34 7 -45 8 -14 2 -22 14 -26 38 -4 19 -7 31 -9 25z"/>
<path d="M5556 2964 c-3 -9 -6 -49 -6 -89 0 -40 3 -80 6 -89 11 -28 24 -17 24
19 0 18 5 57 11 85 l11 50 44 0 c30 0 44 4 44 13 0 23 -9 25 -52 14 -31 -8
-43 -7 -46 1 -5 17 -29 15 -36 -4z"/>
<path d="M5757 2973 c-19 -18 -3 -32 46 -38 55 -8 77 -19 77 -41 0 -10 -21
-14 -77 -16 -75 -3 -78 -4 -81 -29 -2 -14 6 -36 17 -50 18 -22 26 -24 110 -25
l91 0 0 45 c0 110 -41 161 -128 161 -27 0 -52 -3 -55 -7z m143 -152 c0 -25
-16 -30 -73 -24 -86 10 -70 43 21 43 44 0 52 -3 52 -19z"/>
<path d="M6307 2959 c-31 -23 -52 -86 -43 -123 10 -40 76 -69 136 -61 27 4 57
13 67 21 16 12 14 13 -27 14 -69 0 -118 11 -124 27 -5 11 9 13 74 11 93 -3
108 5 94 54 -20 68 -122 101 -177 57z m154 -44 c10 -10 16 -22 13 -26 -3 -5
-43 -9 -90 -9 -69 0 -86 3 -91 16 -4 11 1 22 13 31 26 20 128 11 155 -12z"/>
<path d="M6713 2973 c-7 -2 -13 -11 -13 -19 0 -8 -4 -14 -10 -14 -5 0 -10 8
-10 17 0 12 -3 14 -11 6 -16 -16 -17 -185 -1 -190 8 -3 12 7 12 34 0 76 29
132 69 133 11 0 13 -13 8 -58 -9 -108 15 -158 27 -54 4 38 14 67 30 87 21 27
26 28 50 17 25 -11 26 -15 26 -87 0 -95 15 -90 23 6 6 77 11 87 44 93 26 5 33
-16 33 -101 0 -34 5 -65 10 -68 6 -4 10 24 10 78 0 108 -13 123 -95 113 -32
-3 -64 -2 -74 4 -16 8 -21 4 -32 -21 l-12 -31 -14 26 c-7 14 -18 26 -23 26 -4
0 -14 2 -22 4 -7 3 -19 2 -25 -1z"/>
<path d="M7090 2950 c-24 -24 -33 -56 -28 -101 2 -27 11 -38 43 -56 52 -29 91
-30 106 -1 9 17 9 20 -2 16 -44 -17 -56 -16 -79 3 -24 20 -24 20 -1 9 29 -15
91 -4 107 18 10 14 12 11 12 -20 0 -48 9 -63 28 -47 9 8 14 32 14 74 0 56 -3
67 -29 94 -26 28 -35 31 -90 31 -47 0 -65 -4 -81 -20z m143 -41 c9 -12 17 -25
17 -30 0 -22 -152 -40 -165 -19 -9 15 12 48 43 65 34 19 81 12 105 -16z"/>
<path d="M7333 2955 c-9 -24 2 -174 14 -186 7 -7 13 15 17 70 8 89 18 102 72
98 20 -1 29 3 29 13 0 19 -47 25 -77 9 -16 -9 -23 -9 -26 0 -5 16 -22 14 -29
-4z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
